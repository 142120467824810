import { useParseData } from '../../../utils/parse.utils';
import { consola } from 'consola';
import { GalleryTypeDto, GalleryTypeEntity, ImageGalleryTypeDto, StyleItemTypeDto, StyleItemTypeEntity, StyleTypeDto, StyleTypeEntity, VideoGalleryTypeDto } from './inspiration.type'

export const useInspirationUtils = (globalSettings: any) => {
    const { getImageField } = useParseData()
    const { generateUrlByNameRoute } = useGenerateUrl(globalSettings)

    const generateGalleryItemDto = (item: GalleryTypeEntity): ImageGalleryTypeDto | VideoGalleryTypeDto | any => {
        try {
            if (item.type === 'image') {
                return generateImageGalleryDto(item) as ImageGalleryTypeDto
            } else {
                return generateVideoGalleryDto(item) as VideoGalleryTypeDto
            }
        } catch (e: any) {
            consola.error('ERR generateGalleryItemDto : ', e)
            return {}
        }
    }
    const generateImageGalleryDto = (item: GalleryTypeEntity): ImageGalleryTypeDto | any => {
        try {
            let output = {
                id: item.id,
                status: item.status,
                type: item.type,
                featured: item.featured,
                thumbnail: item.thumbnail,
                title: item.title,
                description: item.description,
                translations: {
                    title: item.title,
                    description: item.description,
                },
                images: item.images?.map((ite: any) => getImageField(ite, 'directus_files_id')),
                seo_detail: item.seo_detail,
                url: generateUrlByNameRoute("gallery_image", {
                    id: item.id,
                    slug: item.slug
                })
            }

            return output
        } catch (e: any) {
            consola.error('ERR generateImageGalleryDto : ', e)
            return {}
        }
    }

    const generateVideoGalleryDto = (item: GalleryTypeEntity): VideoGalleryTypeDto | any => {
        try {
            let output = {
                id: item.id,
                status: item.status,
                type: item.type,
                title: item.title,
                thumbnail: item.thumbnail,
                featured: item.featured,
                video: item.video,
                translations: {
                    title: item.title,
                },
                seo_detail: item.seo_detail
            }

            return output
        } catch (e: any) {
            consola.error('ERR generateVideoGalleryDto : ', e)
            return {}
        }
    }

    const generateStyleItemDto = (item: StyleItemTypeEntity): StyleItemTypeDto | any => {
        try {
            let output = {
                ...item,
       
            }
            return output
        } catch (e: any) {
            consola.error('ERR generateStyleItemDto: ', e)
            return {}
        }
    }

    const generateStyleDto = (item: StyleTypeEntity): StyleTypeDto | any => {
        try {
            let output: any = {
                ...item,
                translations: {
                    title: item.title,
                    description: item.description,
                },
                url: generateUrlByNameRoute("style_detail", {
                    id: item.id,
                    slug: item.slug
                })
            }
            if (item.style_items && Array.isArray(item.style_items) && item.style_items[0]) {
                output['style_items'] = item.style_items.map((ite: any) => generateStyleItemDto(ite) as StyleItemTypeDto)
            }

            if (item.related_galleries && Array.isArray(item.related_galleries) && item.related_galleries[0]) {
                output['related_galleries'] = item.related_galleries.map((ite: any) => generateGalleryItemDto(ite.inspiration_galleries_id))

            }
            return output
        } catch (e: any) {
            consola.error('ERR generateStyleDto: ', e)
            return {}
        }
    }

    return {
        generateStyleDto,
        generateStyleItemDto,
        generateVideoGalleryDto,
        generateImageGalleryDto,
        generateGalleryItemDto

    }
}
