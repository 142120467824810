import { consola } from "consola";
import { useInspirationUtils } from "./useInspirationUtils";
import { ImageGalleryTypeDto, StyleTypeDto, VideoGalleryTypeDto } from "./inspiration.type";
export const useInspiration = () => {

    const globalSettings = inject("globalSettings");
    const { getItems } = useDirectusItems();
    const { calTotalPage } = useParseData();
    const { generateStyleDto, generateGalleryItemDto } = useInspirationUtils(globalSettings)

    const getFeaturedStyles = async (
        options?: {
            query?: { limit: any }
        }
    ) => {
        return getItems({
            collection: "styles",
            params: {
                fields: [
                    "id",
                    "status",
                    "title",
                    "slug",
                    "thumbnail",
                    "featured"
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {
                            featured: {
                                _eq: true
                            }
                        }
                    ]
                },
                sort: ['-id'],
                limit: options?.query?.limit || -1
            }
        }).then((data: any) => {
            return data.map((ite: any) => generateStyleDto(ite) as StyleTypeDto);
        }).catch(e => {
            consola.error("Error: getFeaturedStyles!");
            consola.debug(e);
            return {};
        })
    }

    const getFeaturedGalleries = async (
        options?: {
            query?: { limit: any }
        }
    ) => {
        return getItems({
            collection: "inspiration_galleries",
            params: {
                fields: [
                    "id",
                    "status",
                    "featured",
                    "type",
                    "title",
                    "slug",
                    "description",
                    "images.*",
                    "video",
                    "thumbnail",
                    "featured"
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {
                            featured: {
                                _eq: true
                            }
                        }
                    ]
                },
                sort: ['-id'],
                limit: options?.query?.limit || -1
            }
        }).then((data: any) => {
            return data.map((ite: any) => generateGalleryItemDto(ite));
        }).catch(e => {
            consola.error("Error: getFeaturedGalleries!");
            consola.debug(e);
            return {};
        })
    }


    const getStyleDetailBySlug = async (options: {
        slug: string,
        seo_fields: Array<any>
    }) => {
        return getItems({
            collection: "styles",
            params: {
                fields: [
                    "id",
                    "status",
                    "date_created",
                    "sort",
                    "title",
                    "intro",
                    "description",
                    "slug",
                    "thumbnail",
                    'related_galleries.*',
                    'related_galleries.inspiration_galleries_id.id',
                    'related_galleries.inspiration_galleries_id.status',
                    'related_galleries.inspiration_galleries_id.title',
                    'related_galleries.inspiration_galleries_id.type',
                    'related_galleries.inspiration_galleries_id.video',
                    'related_galleries.inspiration_galleries_id.thumbnail',
                    'related_galleries.inspiration_galleries_id.slug',
                    'style_items.id',
                    'style_items.status',
                    'style_items.image',
                    'style_items.description',
                    'seo_detail.*',
                    ...options.seo_fields || []
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {

                            slug: options.slug
                        }
                    ]
                },
            }
        }).then((data: any) => {
            return generateStyleDto(data[0]) as StyleTypeDto;
        }).catch(e => {
            consola.error("Error: getStyleDetailBySlug!");
            consola.debug(e);
            return {};
        })
    }

    const getGalleryImages = async (options?: {
        query?: { limit?: any, page?: any }
    }) => {
        return getItems({
            collection: "inspiration_galleries",
            params: {
                fields: [
                    "id",
                    "status",
                    "type",
                    "featured",
                    "title",
                    "thumbnail",
                    "slug",
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {

                            type: {
                                _eq: 'image'
                            }
                        }
                    ]
                },
                limit: options?.query?.limit || 12,
                page: options?.query?.page || 1,
                meta: 'filter_count'
            }
        }).then((res: any) => {
            return {
                albums: res.data.map((ite: any) => generateGalleryItemDto(ite) as ImageGalleryTypeDto),
                total_page: calTotalPage(+res.meta.filter_count, options?.query?.limit || 12),
                total_count: res.meta.filter_count
            }
        }).catch(e => {
            consola.error("Error: getGalleryImages!");
            consola.debug(e);
            return {};
        })
    }

    const getGalleryVideos = async (options?: {
        query?: { limit?: any, page?: any }
    }) => {
        return getItems({
            collection: "inspiration_galleries",
            params: {
                fields: [
                    "id",
                    "status",
                    "type",
                    "featured",
                    "title",
                    "thumbnail",
                    "slug",
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {

                            type: {
                                _eq: 'video'
                            }
                        }
                    ]
                },
                limit: options?.query?.limit || 12,
                page: options?.query?.page || 1,
                meta: 'filter_count'
            }
        }).then((res: any) => {
            return {
                videos: res.data.map((ite: any) => generateGalleryItemDto(ite) as VideoGalleryTypeDto),
                total_page: calTotalPage(+res.meta.filter_count, options?.query?.limit || 12),
                total_count: res.meta.filter_count
            }
        }).catch(e => {
            consola.error("Error: getGalleryVideos!");
            consola.debug(e);
            return {};
        })
    }

    const getInspirationGalleries = async (options?: {
        query?: { limit?: any, page?: any }
    }) => {
        return getItems({
            collection: "inspiration_galleries",
            params: {
                fields: [
                    "id",
                    "status",
                    "type",
                    "featured",
                    "title",
                    "thumbnail",
                    "slug",
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                    ]
                },
                limit: options?.query?.limit || 12,
                page: options?.query?.page || 1,
                meta: 'filter_count'
            }
        }).then((res: any) => {
            return {
                galleries: res.data.map((ite: any) => generateGalleryItemDto(ite)),
                total_page: calTotalPage(+res.meta.filter_count, options?.query?.limit || 12),
                total_count: res.meta.filter_count,
                current_page: options?.query?.page || 1
            }
        }).catch(e => {
            consola.error("Error: getInspirationGalleries!");
            consola.debug(e);
            return {};
        })
    }

    const getGalleryDetailBySlug = async (options: {
        slug: string,
        seo_fields: Array<any>
    }) => {
        return getItems({
            collection: "inspiration_galleries",
            params: {
                fields: [
                    "id",
                    "status",
                    "sort",
                    "user_created",
                    "date_created",
                    "user_updated",
                    "date_updated",
                    "type",
                    "featured",
                    "title",
                    "description",
                    "thumbnail",
                    "slug",
                    "images.*",
                    'seo_detail.*',
                    ...options.seo_fields || []
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {

                            slug: options.slug
                        }
                    ]
                },
            }
        }).then((data: any) => {
            return generateGalleryItemDto(data[0]) as ImageGalleryTypeDto;
        }).catch(e => {
            consola.error("Error: getGalleryDetailBySlug!");
            consola.debug(e);
            return {};
        })
    }

    return {
        getFeaturedStyles,
        getFeaturedGalleries,
        getStyleDetailBySlug,
        getGalleryImages,
        getGalleryVideos,
        getGalleryDetailBySlug,
        getInspirationGalleries
    }
}
